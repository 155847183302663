/**
 * 集卡玩法
 */
export default [
    {
        path: '/projectTasks/playCard/index',
        component: resolve => require(['../../components/pages/projectTasks/playCard/index.vue'], resolve),
        meta: {title: '集卡玩法'},
        children: [
            {
                path: '',
                redirect: '/projectTasks/playCard/config'
            },
            {
                path: '/projectTasks/playCard/config',
                component: resolve => require(['../../components/pages/projectTasks/playCard/config.vue'], resolve),
                meta: {title: '集卡玩法'},
                props: true // 启用 props 传递
            },
            {
                path: '/projectTasks/playCard/water',
                component: resolve => require(['../../components/pages/projectTasks/playCard/water.vue'], resolve),
                meta: {title: '集卡流水'},
                props: true 
            },
            {
                path: '/projectTasks/playCard/synthesis',
                component: resolve => require(['../../components/pages/projectTasks/playCard/synthesis.vue'], resolve),
                meta: {title: '合成记录'},
                props: true 
            },
            {
                path: '/projectTasks/playCard/user',
                component: resolve => require(['../../components/pages/projectTasks/playCard/user.vue'], resolve),
                meta: {title: '集卡用户'},
                props: true 
            },
            {
                path: '/projectTasks/playCard/giveList',
                component: resolve => require(['../../components/pages/projectTasks/playCard/giveList.vue'], resolve),
                meta: {title: '转增发起记录'},
                props: true 
            },
            {
                path: '/projectTasks/playCard/giveSuccess',
                component: resolve => require(['../../components/pages/projectTasks/playCard/giveSuccess.vue'], resolve),
                meta: {title: '转增成功记录'},
                props: true 
            },
        ]
    },
]